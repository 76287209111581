var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Agregar Gasto ")]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Nuevo gasto ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Desccripcion","dense":"","outlined":""},model:{value:(_vm.datos.descripcion),callback:function ($$v) {_vm.$set(_vm.datos, "descripcion", $$v)},expression:"datos.descripcion"}}),_c('v-text-field-money',{attrs:{"label":'Monto $',"properties":{
                                prefix: '$',
                                readonly: false,
                                disabled: false,
                                outlined: true,
                                clearable: true,
                                persistentHint: true,
                                dense: true,
                            
                            },"options":{
    locale: 'es-Ve',
    length: 11,
    precision: 2,
    empty: null,
}},model:{value:(_vm.datos.monto),callback:function ($$v) {_vm.$set(_vm.datos, "monto", $$v)},expression:"datos.monto"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.agregar()}}},[_vm._v(" Agregar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
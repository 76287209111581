<template>
    <span>
        <v-btn color="primary"   @click="dialog = true" x-small>
            Agregar Gasto
        </v-btn>
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Nuevo gasto
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea label="Desccripcion" dense outlined v-model="datos.descripcion"></v-textarea>


                            <v-text-field-money v-model="datos.monto" v-bind:label="'Monto $'" v-bind:properties="{
                                prefix: '$',
                                readonly: false,
                                disabled: false,
                                outlined: true,
                                clearable: true,
                                persistentHint: true,
                                dense: true,
                            
                            }" v-bind:options="{
    locale: 'es-Ve',
    length: 11,
    precision: 2,
    empty: null,
}" />

                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        Cerrar
                    </v-btn>
                    <v-btn color="primary" text @click="agregar()">
                        Agregar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>
<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import Icons from '@/views/icons/Icons.vue'
export default {
  components: { Icons },
    setup(props, context) {
        const dialog = ref(false)
        const datos = ref({
            id: -1,
            descripcion: "",
            monto: ""
        })

        const validarDatos = () => {
            var validado = true

            if (datos.value.descripcion == undefined || datos.value.descripcion == null || datos.value.descripcion == '') {
                validado = false
                store.commit('setAlert', {
                    message: "Debe agregar una descripcion valida",
                    type: 'info',
                })
            }

            if (Number(datos.value.monto) == NaN || Number(datos.value.monto) <= 0) {
                validado = false
                store.commit('setAlert', {
                    message: "Debe agregar un monto valido",
                    type: 'info',
                })
            }

            return validado
        }
        const agregar = () => {
            if (validarDatos()) {
                context.emit("getGasto", datos.value)
                datos.value = {
                    id: -1,
                    descripcion: "",
                    monto: ""
                }
            }
        }
        return {
            agregar,
            dialog,
            datos
        }
    },
}
</script>
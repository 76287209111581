//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../";
import router from '@/router'


class SeguridadService {
  
  obtenerPuestos(params) {
    return http.get("/puesto/puestoListar", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  

   
  obtenerPuestos2(params) {
    return http.get("/puesto/puestoListar2", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  
  


  obtenerPuestosTipo() {
    return http.get("/puesto/puestoTipoListar")
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  obtenergrupoNegocio() {
    return http.get("/puesto/grupoNegocioListar")
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  
  ReportePuestoCuentaGlobal(datos) {
    return http.get("/puesto/ReportePuestoCuentaGlobal", { params: datos })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  CXPPuestoListar(datos) {
    return http.get("/puesto/CXPPuestoListar", { params: datos })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }

  modificarPuesto(data) {
    return http.post("/puesto/puestoNuevo", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  CuentaPuestoNuevo(data) {
    return http.post("/puesto/CuentaPuestoNuevo", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  
  subirImgPuesto(data) {
    return http.post("/puesto/upload", data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    })
  } 
}


export default new SeguridadService();
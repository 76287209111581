<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" max-width="400px">
            <v-card>
                <v-card-title>Confirmacion</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="text-center">Totales</div>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>
                                        Dias
                                    </td>
                                    <td class="text-right">
                                        ${{ totales.dias.seleccionados.toFixed(2) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        CXC
                                    </td>
                                    <td class="text-right">
                                        ${{ totales.cxc.seleccionados.toFixed(2) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Gastos
                                    </td>
                                    <td class="text-right">
                                        ${{ totales.gastos.toFixed(2) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Pagos
                                    </td>
                                    <td class="text-right">
                                        ${{ totales.pagos.toFixed(2) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                </tr>

                                <tr>
                                    <td>
                                        <h4><span class="primary--text">Total pago</span> </h4>
                                    </td>
                                    <td class="text-right">
                                        <h4><span class="primary--text">
                                                ${{ (totales.cxc.seleccionados + totales.gastos +
                                                        totales.pagos).toFixed(2)
                                                }}
                                            </span>
                                        </h4>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span :class="(totales.dias.seleccionados -
                                    (totales.cxc.seleccionados
                                        + totales.gastos + totales.pagos)) > 0 ? 'error--text' : ''">Resta</span>

                                    </td>
                                    <td class="text-right">
                                        <span :class="(totales.dias.seleccionados -
                                    (totales.cxc.seleccionados
                                        + totales.gastos + totales.pagos)) > 0 ? 'error--text' : ''">
                                            ${{ (totales.dias.seleccionados -
                                                    (totales.cxc.seleccionados
                                                        + totales.gastos + totales.pagos)).toFixed(2)
                                            }}</span>

                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-text>
                    <div class="text-center">Estado</div> 
                    <v-item-group  v-model="selected" mandatory>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-item v-slot="{ active, toggle }">
                                        <v-card :color="active ? 'primary' : ''" class="d-flex align-center" dark
                                            height="100" @click="toggle ">
                                            <v-scroll-y-transition>
                                                <div   class=" white--text   flex-grow-1 text-center">
                                                    Presupuesto
                                                </div>
                                            </v-scroll-y-transition>
                                        </v-card>
                                    </v-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-item v-slot="{ active, toggle }">
                                        <v-card :color="active ? 'primary' : ''" class="d-flex align-center" dark
                                        height="100"  @click="toggle">
                                            <v-scroll-y-transition>
                                                <div   class="white--text  flex-grow-1 text-center">
                                                    Finalizado
                                                </div>
                                            </v-scroll-y-transition>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-item-group>
                  
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="dialog = false">
                            Cancelar
                        </v-btn>
                        <v-btn color="primary" text @click="getGuardar()">
                            Confirmar
                        </v-btn>
                   
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
export default {
    setup(props, context) {
        const dialog = ref(false)
        const estado = ref("49")
        const totales = ref({})
        const abrirComanda = (datos) => {
            selected.value = 0
            estado.value =  '49'
            dialog.value = true
            totales.value = datos
        }
        const selected = ref(0)
        const getGuardar = () => {
            context.emit("getGuardar", estado.value)
            dialog.value = false
        }
        watch(selected,()=>{
            
            if (selected.value == 0){
                estado.value =  '49'
            }else{
                estado.value =  '50'
            }
            
        })
       
        return {
            estado,
            dialog,
            getGuardar,
            abrirComanda,
            totales,
            selected
        }
    },
}
</script>
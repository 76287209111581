
<template>
  <div>
    <v-card>
      <v-card-title
        >Lista Cuentas

        <v-btn icon @click="cargarDatos()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
          <v-icon>{{ icons.mdiCached }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <dos-fechas
              :fechas="[
                FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
                FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
              ]"
              @getFechas="getFechas"
              :icon="true"
            ></dos-fechas>
          </v-col>
          <v-col cols="12" md="4">
            <select-puesto
              @getPuesto="getPuesto"
              :clearable="true"
              :dense="true"
              :icon="true"
              :outlined="true"
            ></select-puesto>
          </v-col>
          <v-col cols="10" md="3">
            <buscar-empleado @Getdatos="getEmpleado" :icon="true"></buscar-empleado>
          </v-col>
          <v-col cols="2" md="1">
            <v-btn icon @click="cargarDatos()" color="primary" :loading="cargandoDatos" :disabled="cargandoDatos">
              <v-icon>{{ icons.mdiMagnify }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="Lista.length > 0">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Filtrar"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="Lista"
        :search="search"
        :items-per-page="5"
        :loading="ListaLoad"
        class="table-kitchen-sink"
      >
        <template #[`item.id`]="{ item }">
          <strong class="text-no-wrap">#{{ item.id }} </strong>
          <br />
          <small class="text-no-wrap">{{ FuncionesGenerales.formatoFecha(item.fecha_creacion, 1) }}</small>
        </template>
        <template #[`item.puesto`]="{ item }">
          {{ item.puesto_json.nombre }}
        </template>
        <template #[`item.fechas`]="{ item }">
          <small class="text-no-wrap">{{ FuncionesGenerales.formatoFecha(item.fechas.inicio, 1) }}</small>
          <br />
          <small class="text-no-wrap">{{ FuncionesGenerales.formatoFecha(item.fechas.fin, 1) }}</small>
        </template>

        <template #[`item.grupo`]="{ item }">
          {{ item.grupo_negocio_json.descripcion }}
        </template>
        <template #[`item.cliente`]="{ item }">
          {{ item.cliente_json.nombre }} {{ item.cliente_json.apellido }}
        </template>
        <template #[`item.empleado`]="{ item }">
          {{ item.empleado_json.nombre }} {{ item.empleado_json.apellido }}
        </template>
        <template #[`item.monto_total`]="{ item }"> ${{ item.monto_total }} </template>
        <template #[`item.estado`]="{ item }">
          <v-chip x-small :color="item.estado == 50 ? 'success' : 'warning'">
            {{ item.estado_json.descripcion }}
          </v-chip>
        </template>
        <template #[`item.accion`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="info" v-bind="attrs" v-on="on" @click="GetItem(item, 0)">
                <v-icon>{{ icons.mdiEye }}</v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="info" :disabled="item.estado == 50" v-bind="attrs" v-on="on" @click="GetItem(item, 1)">
                <v-icon>{{ icons.mdiEye }}</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
  
<script>
import { onBeforeMount, ref, context } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEye,
} from '@mdi/js'
import goTo from 'vuetify/lib/services/goto'
import PuestoService from '@/api/servicios/PuestoService'
import config from '@/api/config'
import ComandaService from '@/api/servicios/ComandaService'
import { now } from 'moment'
import BuscarEmpleado from '../../../archivo/empleado/components/BuscarEmpleado.vue'
import DosFechas from '@/components/DosFechas.vue'
import FuncionesGenerales from '@/funciones/funciones'
import SelectPuesto from '../../../archivo/puesto/components/SelectPuesto.vue'
import store from '@/store'
export default {
  components: { BuscarEmpleado, DosFechas, SelectPuesto },
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const filtros = ref({
      id: -1,
      id_empleado: -1,
      id_puesto: -1,
      fecha: {
        desde: new Date(),
        hasta: new Date(),
      },
      estado: -1,
    })
    const GetItem = (item, tipo) => {
      const dato = {
        tipo: tipo,
        item: item,
      }
      context.emit('GetItem', dato)
      goTo(0)
    }

    onBeforeMount(() => {
      cargarDatos()
    })
    const getFechas = datos => {
      console.log(datos)
      filtros.value.fecha.desde = datos.desde
      filtros.value.fecha.hasta = datos.hasta
    }
    const getEmpleado = dato => {
      if (dato != undefined) {
        filtros.value.id_empleado = dato.id
      } else {
        filtros.value.id_empleado = -1
      }
    }

    const getPuesto = dato => {
      if (dato != undefined) {
        filtros.value.id_puesto = dato.id
      } else {
        filtros.value.id_puesto = -1
      }
    }

    const cargarDatosNuevo = () => {
      filtros.value = {
        id: -1,
        id_empleado: -1,
        id_puesto: -1,
        fecha: {
          desde: new Date(),
          hasta: new Date(),
        },
        estado: -1,
      }

      cargarDatos()
    }
    const cargarDatos = () => {
      try {
        cargandoDatos.value = true
        PuestoService.CXPPuestoListar(filtros.value)
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',

          error: error,
          funcion: 'cargarDatos',
        })
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEye,
      },
      search,
      headers: [
        { text: '#', value: 'id' },
        { text: 'Puesto', value: 'puesto' },
        { text: 'Fechas', value: 'fechas' },
        { text: 'Emplado', value: 'empleado' },
        { text: 'Observacion', value: 'observacion' },
        { text: 'Dias', value: 'totales_cierre_puesto' },
        { text: 'Total Pagos', value: 'total_pagos' },
        { text: 'Estado', value: 'estado' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      GetItem,
      cargarDatos,
      config,
      cargandoDatos,
      filtros,
      getFechas,
      getEmpleado,
      FuncionesGenerales,
      cargarDatosNuevo,
      getPuesto,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  
<template>
    <span >
        <v-btn :disabled="disabled ? disabled : false" :color="colorBtn ? colorBtn : 'warning'" x-small @click="dialog = true">
            {{ TxtoBtn ? TxtoBtn : 'Pagos' }}
        </v-btn>
        
        <v-dialog scrollable v-model="dialog" width="600" persistent>
            <template>

            </template>

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Pagos
                </v-card-title>

                <v-card-text>
                    <formas-pagos-general ref="formaPagoRef" :monedas="monedas" :indFecha="true"
                        :valorMaximo="valorMaximo" :pagosInt="pagosRef" :dialog="dialog">
                    </formas-pagos-general>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="dialog = false">
                        Cerrar
                    </v-btn>
                    <v-btn color="primary" text @click="GuardarPagos()">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import FormasPagosGeneral from '@/views/sistema/comanda/dashboard/componentes/FormasPagosGeneral.vue'
import store from '@/store'
export default {
    props: {
        pagos: Array,
        monedas: Array,
        valorMaximo: Number,
        colorBtn: String,
        TxtoBtn: String,
        disabled: Boolean,
        indAbono: Boolean
    },
    components: {
        FormasPagosGeneral
    },
    setup(props, context) {
        const formaPagoRef = ref(null)
        const dialog = ref(false)
        const pagosRef = ref([])

        const pagoINIT = {
            id: 0,
            moneda: { id: '', simbolo: '', tasa: 1 },
            tipo_pago: '',
            monto_moneda: '',
            monto: 0,
            referencia: '',
            monto_real: '',
            nuevo: true,
            igtf: 0
        }
        const totalPagos = (val) => {
            let total = 0

            val.forEach(element => {
                total += Number(element.monto_real)
            });

            return total
        }



        const GuardarPagos = () => {
            if (props.valorMaximo != -1 && props.valorMaximo != undefined) {
               
                if (totalPagos(formaPagoRef.value.pagos).toFixed(2) == props.valorMaximo.toFixed(2)) {
                    console.log("formaPagoRef.value.pagos")
                    console.log(formaPagoRef.value.pagos)
                    context.emit('GetPagos', formaPagoRef.value.pagos)
                    // props.pagos = formaPagoRef.value.pagos
                    dialog.value = false
                } else {
                    if (props.indAbono ){
                        console.log("formaPagoRef.value.pagos")
                        console.log(formaPagoRef.value.pagos)
                        context.emit('GetPagos', formaPagoRef.value.pagos)
                        // props.pagos = formaPagoRef.value.pagos
                        dialog.value = false

                    }else{
                        store.commit('setAlert', {
                        message: 'Debe cubrir el pago completo',
                        type: 'warning',
                    })
                    }
                    
                }
            } else {
                console.log("formaPagoRef.value.pagos")
                console.log(formaPagoRef.value.pagos)
                context.emit('GetPagos', formaPagoRef.value.pagos)
                // props.pagos = formaPagoRef.value.pagos
                dialog.value = false
            }


        }

        watch(dialog, () => {
            if (dialog.value == true) {
                if (props.pagos != undefined) {

                    pagosRef.value = props.pagos

                } else {
                    pagosRef.value = []
                }

            }
        })

        return {
            dialog,
            formaPagoRef,
            GuardarPagos,
            pagosRef
        }
    },
}
</script>
  
<template>
  <v-select
    :prepend-icon="icon ? icons.mdiStore : null"
    :clearable="clearable"
    hide-details
    :disabled="disabled"
    v-model="puestoSelect"
    :items="puestos"
    label="Locatario/Area"
    item-value="id"
    item-text="nombre"
    :dense="outlined"
    :outlined="outlined"
    :loading="cargando"
  >
  </v-select>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import PuestoService from '@/api/servicios/PuestoService'
import ProductosService from '@/api/servicios/ProductosService'
import { mdiStore } from '@mdi/js'
import store from '@/store'
export default {
  props: {
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    todos: Boolean,
    default: Number,
    clearable: Boolean,
    storePuesto: {
      type: Boolean,
      default: false,
    },
    modulos:{
      type: Array,
      default: null
    }
  },
  setup(props, contex) {
    const puestos = ref([])
    const puestoSelect = ref(null)
    const cargando = ref(false)
    onBeforeMount(() => {
      cargarDatos()
      if (props.default != undefined) {
        puestoSelect.value = props.default
      }
    })

    const cargarDatos = () => {
      puestos.value = []
     

      if (props.storePuesto) {
        const puestoss = []
        store.state.user.arbol.forEach(element => {
          if (element.id_puesto > -1) {
            puestoss.push({ id: element.id_puesto, nombre: element.nombre })
          }
        })

        puestos.value = puestoss
      } else { 
        cargando.value = true
        ProductosService.obtenerPuestos({   modulos: props.modulos?.join(','), id_usuario: store.state.user.id })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              
              if (props.todos == true) {
                puestos.value.push({ id: -1, nombre: 'TODOS' })
              }
              puestos.value = puestos.value.concat(response.data.datos)
            }
            console.log(response.data)
          })
          .catch(err => {
            console.error(err)
            store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargardatos',
          })
          })
          .finally(() => {
            cargando.value = false
          })
      }
    }
    watch(puestoSelect, () => {
      const valor = puestos.value.find(item => item.id == puestoSelect.value)
      contex.emit('getPuesto', valor == undefined ? null : valor)
    })

    return {
      icons: {
        mdiStore,
      },
      puestos,
      puestoSelect,
      cargando,
    }
  },
}
</script>
<template>
  <v-card>
    <v-card-title> Procesar cuentas locatario </v-card-title>
    <v-card-text>
      <v-row>
        <v-col lg="8" md="8" sm="12" cols="12">
          <cabecera-empresa></cabecera-empresa>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <div>
            <h2 v-if="!soloVer && datos.id <= 0" class="pt-12 pb-2">Cuenta # <small>Nuevo</small></h2>
            <h2 v-if="soloVer" class="pt-12 pb-2">
              {{ datos.estado == '49' ? 'Presupuesto' : 'Cuenta' }} #
              <small>{{ datos.id }} </small>
            </h2>
            <span v-if="soloVer" class="m-0 p-0">
              Fecha creacion: {{ FuncionesGenerales.formatoFecha(datos.fecha_creacion, 3) }}
            </span>
            <br />
            <span v-if="soloVer" class="m-0 p-0">
              Empleado: {{ datos.empleado.nombre }} {{ datos.empleado.apellido }}</span
            >
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-4 mb-2">
        <v-col md="6" cols="12">
          <div v-if="datos.dias.length == 0">
            <select-puesto @getPuesto="getPuesto" :icon="true" :dense="true" :outlined="true"> </select-puesto>
          </div>
          <div v-if="datos.dias.length > 0">
            <h4>
              Locatario
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon color="warning" @click="Reiniciar()">
                    <v-icon>{{ icons.mdiCached }}</v-icon>
                  </v-btn>
                </template>
                <span>Reiniciar</span>
              </v-tooltip>
            </h4>
            <span>Nombre: {{ locatario.nombre }} </span>
            <br />
            <span>Encargado: {{ FuncionesGenerales.camelCase(locatario.encargado) }}</span>
            <br />
            <span>Telefono: {{ locatario.telefono }}</span>
          </div>
        </v-col>
        <v-col md="6" cols="12">
          <div v-if="datos.dias.length == 0">
            <dos-fechas
              :fechas="[
                FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
                FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
              ]"
              @getFechas="getFechas"
              :icon="true"
            ></dos-fechas>
            <div class="text-right mt-4">
              <v-btn color="primary" small :disabled="!validarFiltros()" @click="buscarDatos()"> Buscar Datos </v-btn>
            </div>
          </div>
          <div class="mt-4" v-if="datos.dias.length > 0">
            <span>Desde: {{ FuncionesGenerales.formatoFecha(filtros.fecha.desde) }} </span>
            <br />
            <span>Hasta: {{ FuncionesGenerales.formatoFecha(filtros.fecha.hasta) }} </span>
            <br />
            <span>Cant Dias: {{ datos.dias.length }} </span>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="datos.dias.length > 0"></v-divider>
      <v-row class="mb-4" v-if="datos.dias.length > 0">
        <v-col>
          <v-card-subtitle class="pt-4 pb-3 secondary white--text">
            <span>
              <v-icon color="white" class="mt-0 pt-0">{{ icons.mdiCart }}</v-icon> Dias
            </span>
          </v-card-subtitle>
          <div>
            <v-simple-table dense fixed-header height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-right">Venta Total</th>
                    <th class="text-right">% Administracion</th>
                    <th class="text-right">Total locatario</th>
                    <th class="text-right">Pagos</th>
                    <th class="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in datos.dias" :key="item.id">
                    <td>
                      <div>
                        <v-checkbox
                          @change="totalizar()"
                          class="mt-0 pt-0"
                          hide-details="true"
                          v-model="item.checked"
                          :label="`# ${item.cierre_dia.id}`"
                          v-if="!soloVer"
                        >
                        </v-checkbox>
                      </div>
                      <span v-if="soloVer">#{{ item.cierre_dia.id }}</span>
                      {{ FuncionesGenerales.formatoFecha(item.cierre_dia.fecha, 1) }}
                    </td>
                    <td class="text-right">${{ item.monto_total.toFixed(2) }}</td>
                    <td class="text-right">${{ item.monto_admin.toFixed(2) }}</td>
                    <td class="text-right">${{ item.monto.toFixed(2) }}</td>
                    <td class="text-right">${{ item.total_pago.toFixed(2) }}</td>
                    <td class="text-right">${{ (item.monto - item.total_pago).toFixed(2) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row class="pt-4">
              <v-col></v-col>
              <v-col cols="12" sm="12" md="6" lg="4">
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <th colspan="2" class="text-center grey2"><small class=" ">Totales</small></th>

                    <tr>
                      <td>Total</td>
                      <td class="text-right">${{ datos.totales.dias.total.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td>Seleccionado</td>
                      <td class="text-right">${{ datos.totales.dias.seleccionados.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td>Diferencia</td>
                      <td class="text-right">${{ datos.totales.dias.pendiente.toFixed(2) }}</td>
                    </tr>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="datos.cxc.length > 0"></v-divider>
      <v-row class="mb-4" v-if="datos.cxc.length > 0">
        <v-col>
          <v-card-subtitle class="mt-4 mb-0 pb-2 secondary white--text">
            <v-row>
              <v-col>
                <span>
                  <v-icon class="mt-0 pt-0" color="white"> {{ icons.mdiCashSync }}</v-icon>
                  Cuentas por cobrar
                </span>
              </v-col>
              <v-col class="text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="soloVer" color="white" dark v-bind="attrs" v-on="on" icon @click="selectCXC(0)">
                      <v-icon>{{ icons.mdiCheckboxBlankOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Deseleccionar todo</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="soloVer" color="white" dark v-bind="attrs" v-on="on" icon @click="selectCXC(-1)">
                      <v-icon>{{ icons.mdiCheckboxMarked }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar todo</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="soloVer"
                      color="white"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="selectCXC(datos.totales.dias.seleccionados)"
                    >
                      <v-icon>{{ icons.mdiCheckboxIntermediate }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar total</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="soloVer" color="white" dark v-bind="attrs" v-on="on" icon @click="selectCXC(100)">
                      <v-icon>{{ icons.mdiCheckboxMarkedOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar monto</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <div>
            <v-simple-table dense fixed-header height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">Cliente</th>
                    <th class="text-right">Total Comanda</th>

                    <th class="text-right">Pagos</th>
                    <th class="text-right">Resta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in datos.cxc" :key="item.id">
                    <td>
                      <div>
                        <v-checkbox
                          class="mt-0 pt-0"
                          hide-details="true"
                          @change="totalizar()"
                          v-model="item.checked"
                          :label="`# ${item.id}`"
                          v-if="!soloVer"
                        >
                        </v-checkbox>
                      </div>
                      <span v-if="soloVer">#{{ item.id }}</span>
                      {{ FuncionesGenerales.formatoFecha(item.fecha, 1) }}
                    </td>
                    <td>{{ item.cliente.nombre }} {{ item.cliente.apellido }}</td>
                    <td class="text-right">${{ item.total_cuentas_por_cobrar.total_por_cobrar.toFixed(2) }}</td>
                    <td class="text-right">${{ item.total_cuentas_por_cobrar.total_pago_por_cobrar.toFixed(2) }}</td>
                    <td class="text-right">
                      ${{
                        (
                          item.total_cuentas_por_cobrar.total_por_cobrar -
                          item.total_cuentas_por_cobrar.total_pago_por_cobrar
                        ).toFixed(2)
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row class="pt-4">
              <v-col></v-col>
              <v-col cols="12" sm="12" md="6" lg="4">
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <th colspan="2" class="text-center grey2"><small class=" ">Totales</small></th>

                    <tr>
                      <td>Total</td>
                      <td class="text-right">${{ datos.totales.cxc.total.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td>Seleccionado</td>
                      <td class="text-right">${{ datos.totales.cxc.seleccionados.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td>Diferencia</td>
                      <td class="text-right">${{ datos.totales.cxc.pendiente.toFixed(2) }}</td>
                    </tr>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="datos.dias.length > 0"></v-divider>
      <v-row v-if="datos.dias.length > 0" class="mb-4">
        <v-col>
          <v-card-subtitle class="mt-4 pb-3 secondary white--text">
            <v-row>
              <v-col>
                <span>
                  <v-icon color="white" class="mt-0 pt-0">{{ icons.mdiCashRefund }}</v-icon>
                  Gastos
                </span>
              </v-col>
              <v-col class="text-right">
                <modal-gastos v-if="!soloVer" @getGasto="agregarGasto"></modal-gastos>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <div>
            <v-simple-table dense fixed-header :height="datos.gastos.length == 0 ? '50px' : '200px'">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">ID</th>
                    <th class="text-left">Descripcion</th>
                    <th class="text-left" colspan="2">Monto $</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in datos.gastos" :key="item.id">
                    <td>
                      {{ item.id < 0 ? 'Nuevo' : item.id }}
                    </td>
                    <td>{{ item.descripcion }}</td>
                    <td class="text-left">${{ Number(item.monto).toFixed(2) }}</td>

                    <td class="text-center">
                      <v-btn v-if="!soloVer" icon color="error" @click="elimnarGasto(item)">
                        <v-icon>{{ icons.mdiDelete }}</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="datos.dias.length > 0"></v-divider>
      <v-row class="mb-4" v-if="datos.dias.length > 0">
        <v-col>
          <v-card-subtitle class="mt-4 mb-0 pb-2 secondary white--text">
            <v-row>
              <v-col>
                <span>
                  <v-icon class="mt-0 pt-0">{{ icons.mdiCashRegister }}</v-icon> Pagos
                </span>
              </v-col>
              <v-col class="text-right">
                <modal-pagos
                  v-if="!soloVer"
                  :valorMaximo="
                    Number(
                      (
                        datos.totales.dias.seleccionados -
                        (datos.totales.cxc.seleccionados + datos.totales.gastos)
                      ).toFixed(2),
                    )
                  "
                  :indAbono="false"
                  :colorBtn="`info`"
                  :TxtoBtn="'Registrar Pago'"
                  :monedas="monedas"
                  :pagos="datos.pagos"
                  @GetPagos="GetPagos"
                >
                </modal-pagos>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <div>
            <v-row class="mt-1">
              <v-list width="100%">
                <v-divider></v-divider>
                <template v-for="(item, index) in datos.pagos">
                  <v-list-item :key="index">
                    <v-list-item-avatar size="30">
                      {{ item.moneda.simbolo }}
                    </v-list-item-avatar>

                    <v-list-item-content class="p-0 m-0">
                      <v-list-item-subtitle class="py-0 my-0">
                        <strong
                          ><small>{{ FuncionesGenerales.formatoFecha(item.fecha, 1) }}</small></strong
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }} </v-list-item-subtitle>

                      <v-list-item-subtitle class="py-0 my-0"
                        >{{ item.moneda.tipo_pago.find(i => i.id == item.tipo_pago).nombre }} {{ item.moneda.simbolo }}:
                        {{ item.monto_moneda }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1">
                        <small>Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''">
                        <small> Ref: {{ item.referencia }}</small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-if="datos.dias.length > 0">
        <v-col class="mt-4">
          <v-textarea
            :disabled="soloVer"
            v-model="datos.observacion"
            dense
            outlined
            name="input-7-1"
            label="Observación"
            value=""
          >
          </v-textarea>
          <div class="text-center">
            <v-btn class="mr-2" small color="secondary" @click="Reiniciar()"> Limpiar </v-btn>
            <v-btn v-if="soloVer" class="mr-2" small color="info"> Imprimir PDF </v-btn>
            <v-btn
              v-if="!soloVer"
              small
              color="primary"
              @click="confirmarGuardado()"
              :disabled="
                FuncionesGenerales.roundToTwo(
                  datos.totales.cxc.seleccionados + datos.totales.gastos + datos.totales.pagos,
                ) !== FuncionesGenerales.roundToTwo(datos.totales.dias.seleccionados) || guardando
              "
              :loading="guardando"
            >
              Guardar
            </v-btn>
          </div>
        </v-col>
        <v-col>
          <v-card-subtitle class="pl-3 pt-3">Totales</v-card-subtitle>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Dias</td>
                  <td class="text-right">${{ datos.totales.dias.seleccionados.toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>CXC</td>
                  <td class="text-right">${{ datos.totales.cxc.seleccionados.toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>Gastos</td>
                  <td class="text-right">${{ datos.totales.gastos.toFixed(2) }}</td>
                </tr>

                <tr>
                  <td>Pagos</td>
                  <td class="text-right">${{ datos.totales.pagos.toFixed(2) }}</td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                </tr>

                <tr>
                  <td>
                    <h4><span class="primary--text">Total pago</span></h4>
                  </td>
                  <td class="text-right">
                    <h4>
                      <span class="primary--text">
                        ${{ (datos.totales.cxc.seleccionados + datos.totales.gastos + datos.totales.pagos).toFixed(2) }}
                      </span>
                    </h4>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      :class="
                        FuncionesGenerales.roundToTwo(
                          datos.totales.cxc.seleccionados + datos.totales.gastos + datos.totales.pagos,
                        ) !== FuncionesGenerales.roundToTwo(datos.totales.dias.seleccionados)
                          ? 'error--text'
                          : ''
                      "
                      >Resta
                    </span>
                  </td>
                  <td class="text-right">
                    <span
                      :class="
                        FuncionesGenerales.roundToTwo(
                          datos.totales.cxc.seleccionados + datos.totales.gastos + datos.totales.pagos,
                        ) !== FuncionesGenerales.roundToTwo(datos.totales.dias.seleccionados)
                          ? 'error--text'
                          : ''
                      "
                    >
                      $
                      {{
                        (
                          FuncionesGenerales.roundToTwo(datos.totales.dias.seleccionados) -
                          FuncionesGenerales.roundToTwo(
                            datos.totales.cxc.seleccionados + datos.totales.gastos + datos.totales.pagos,
                          )
                        ).toFixed(2)
                      }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
    <guardar-formulario ref="guardarFormularioRef" @getGuardar="getGuardar"></guardar-formulario>
  </v-card>
</template>

<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import SelectPuesto from '../../../archivo/puesto/components/SelectPuesto.vue'
import DosFechas from '@/components/DosFechas.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { onBeforeMount, ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import {
  mdiCached,
  mdiDelete,
  mdiPlus,
  mdiCheckboxMarkedOutline,
  mdiCheckboxIntermediate,
  mdiCheckboxMarked,
  mdiCheckboxBlankOutline,
  mdiCart,
  mdiCashRefund,
  mdiCashRegister,
} from '@mdi/js'
import ModalGastos from './ModalGastos.vue'
import ModalPagos from '../../../ventas/cuentaxcobrar/components/ModalPagos.vue'
import GuardarFormulario from './GuardarFormulario.vue'
import PuestoService from '@/api/servicios/PuestoService'
export default {
  components: { CabeceraEmpresa, SelectPuesto, DosFechas, ModalGastos, ModalPagos, GuardarFormulario },
  setup(props, context) {
    const mdiCashSync =
      'M3 6V18H13.32C13.1 17.33 13 16.66 13 16H7C7 14.9 6.11 14 5 14V10C6.11 10 7 9.11 7 8H17C17 9.11 17.9 10 19 10V10.06C19.67 10.06 20.34 10.18 21 10.4V6H3M12 9C10.3 9.03 9 10.3 9 12S10.3 14.94 12 15C12.38 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.61C14.85 10.28 13.59 8.97 12 9M19 11L21.25 13.25L19 15.5V14C17.15 14 15.94 15.96 16.76 17.62L15.67 18.71C13.91 16.05 15.81 12.5 19 12.5V11M19 22L16.75 19.75L19 17.5V19C20.85 19 22.06 17.04 21.24 15.38L22.33 14.29C24.09 16.95 22.19 20.5 19 20.5V22'
    const soloVer = ref(false)
    const guardando = ref(false)
    const guardarFormularioRef = ref(null)

    const datos = ref({
      id: -1,
      dias: [],
      cxc: [],
      gastos: [],
      pagos: [],
      observacion: '',
      totales: {
        dias: {
          total: 0,
          seleccionados: 0,
          pendiente: 0,
        },
        cxc: {
          total: 0,
          seleccionados: 0,
          pendiente: 0,
        },
        gastos: 0,
        pagos: 0,
      },
    })
    const gastoInit = {
      id: -1,
      descripcion: '',
      monto: '',
    }
    const monedas = ref([])

    const MonedaPais = ref({})
    let contador = -90000
    const nuevoContador = () => {
      contador += 1

      return contador
    }
    const totalizar = () => {
      datos.value.totales.dias = {
        total: 0,
        seleccionados: 0,
        pendiente: 0,
      }
      datos.value.dias.forEach(item => {
        datos.value.totales.dias.total += FuncionesGenerales.roundToTwo(item.monto - item.total_pago)
        if (item.checked) {
          datos.value.totales.dias.seleccionados += FuncionesGenerales.roundToTwo(item.monto - item.total_pago)
        } else {
          datos.value.totales.dias.pendiente += FuncionesGenerales.roundToTwo(item.monto - item.total_pago)
        }
      })

      datos.value.totales.cxc = {
        total: 0,
        seleccionados: 0,
        pendiente: 0,
      }
      datos.value.cxc.forEach(item => {
        datos.value.totales.cxc.total += FuncionesGenerales.roundToTwo(
          item.total_cuentas_por_cobrar.total_por_cobrar - item.total_cuentas_por_cobrar.total_pago_por_cobrar,
        )
        if (item.checked) {
          datos.value.totales.cxc.seleccionados += FuncionesGenerales.roundToTwo(
            item.total_cuentas_por_cobrar.total_por_cobrar - item.total_cuentas_por_cobrar.total_pago_por_cobrar,
          )
        } else {
          datos.value.totales.cxc.pendiente += FuncionesGenerales.roundToTwo(
            item.total_cuentas_por_cobrar.total_por_cobrar - item.total_cuentas_por_cobrar.total_pago_por_cobrar,
          )
        }
      })

      datos.value.totales.gastos = 0
      datos.value.gastos.forEach(item => {
        datos.value.totales.gastos += FuncionesGenerales.roundToTwo(Number(item.monto))
      })

      datos.value.totales.pagos = 0
      datos.value.pagos.forEach(item => {
        datos.value.totales.pagos += FuncionesGenerales.roundToTwo(Number(item.monto_real))
      })
    }

    const consultarMonedas = () => {
      MaestroService.listarMonedas()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            monedas.value = response.data.datos
            MonedaPais.value = monedas.value.find(item => item.meneda_pais)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    }
    const gasto = ref(JSON.parse(JSON.stringify(gastoInit)))
    const locatario = ref({})
    const validarFiltros = () => {
      let validado = true
      if (filtros.value.id_puesto == null || filtros.value.id_puesto == undefined) {
        validado = false
      }
      if (filtros.value.fecha.desde == null || filtros.value.fecha.desde == undefined) {
        validado = false
      }

      return validado
    }

    const filtros = ref({
      id_puesto: null,
      fecha: {
        desde: new Date(),
        hasta: new Date(),
      },
    })
    onBeforeMount(() => {
      consultarMonedas()
    })

    const agregarGasto = gasto => {
      gasto.id = nuevoContador()
      datos.value.gastos.push(gasto)
      totalizar()
    }
    const elimnarGasto = item => {
      const array = datos.value.gastos.filter(i => i.id != item.id)
      datos.value.gastos = array
    }

    const buscarDatos = () => {
      datos.value = {
        id: -1,
        dias: [],
        cxc: [],
        gastos: [],
        pagos: [],
        observacion: '',
        totales: {
          dias: {
            total: 0,
            seleccionados: 0,
            pendiente: 0,
          },
          cxc: {
            total: 0,
            seleccionados: 0,
            pendiente: 0,
          },
          gastos: 0,
          pagos: 0,
        },
      }
      MaestroService.ReporteCXPPuesto(filtros.value)
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            if (response.data.datos.dias.length > 0) {
              datos.value.dias = response.data.datos.dias
              datos.value.cxc = response.data.datos.cxc
              totalizar()
              selectCXC(datos.value.totales.dias.seleccionados)
            } else {
              store.commit('setAlert', {
                message: 'No hay cuentas pendientes',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: 'ups' + response.data.mensaje,
              type: 'error',

              error: response,
              funcion: 'buscarDatos',
            })
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'buscarDatos',
          })
        })
        .finally(() => {})
    }

    const selectCXC = monto => {
      if (monto == -1) {
        datos.value.cxc.map(item => (item.checked = true))
      } else {
        let sum = 0
        datos.value.cxc.map(item => {
          if (
            sum +
              (item.total_cuentas_por_cobrar.total_por_cobrar - item.total_cuentas_por_cobrar.total_pago_por_cobrar) <=
            monto
          ) {
            item.checked = true
            sum += item.total_cuentas_por_cobrar.total_por_cobrar - item.total_cuentas_por_cobrar.total_pago_por_cobrar
          } else {
            item.checked = false
          }
        })
      }

      totalizar()
    }

    const GetPagos = datosIn => {
      datos.value.pagos = datosIn
      totalizar()
    }

    const getPuesto = dato => {
      if (dato == null) {
        filtros.value.id_puesto = dato
      } else {
        filtros.value.id_puesto = dato.id
      }
      locatario.value = dato
    }
    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
    }
    const Reiniciar = () => {
      filtros.value = {
        id_puesto: null,
        fecha: {
          desde: new Date(),
          hasta: new Date(),
        },
      }
      datos.value = {
        id: -1,
        dias: [],
        cxc: [],
        gastos: [],
        pagos: [],
        observacion: '',
        totales: {
          dias: {
            total: 0,
            seleccionados: 0,
            pendiente: 0,
          },
          cxc: {
            total: 0,
            seleccionados: 0,
            pendiente: 0,
          },
          gastos: 0,
          pagos: 0,
        },
      }
      soloVer.value = false
    }

    const confirmarGuardado = () => {
      guardarFormularioRef.value.abrirComanda(datos.value.totales)
    }

    const getGuardar = datosIn => {
      guardando.value = true
      let parametro = { ...datos.value }
      parametro.cxc = datos.value.cxc.filter(item => item.checked)
      parametro.dias = datos.value.dias.filter(item => item.checked)
      parametro.estado = datosIn
      parametro.id_empleado = store.state.user.id_empleado
      parametro.fechas = filtros.value.fecha
      parametro.id_puesto = filtros.value.id_puesto
      console.log(parametro)

      PuestoService.CuentaPuestoNuevo(parametro)
        .then(response => {
          if (response.data.mensaje == 'CUENTA GUARDADO CON EXITO') {
            store.commit('setAlert', {
              message: 'Se guardo la cuenta exitosamente',
              type: 'success',
            })
            soloVer.value = true
            datos.value.id = response.data.datos.id
            datos.value.fecha_creacion = response.data.datos.fecha_creacion
            datos.value.empleado = store.state.user
            datos.value.cxc = datos.value.cxc.filter(item => item.checked)
            datos.value.dias = datos.value.dias.filter(item => item.checked)
            datos.value.estado = parametro.estado
          } else {
            store.commit('setAlert', {
              message: 'ups ' + response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: 'ups ' + err,
            type: 'error',

            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'getGuardar',
          })
        })
        .finally(() => {
          guardando.value = false
          context.emit('getGuardar', datos.value)
        })
    }

    const VerCuenta = dato => {
      datos.value = {
        id: -1,
        dias: [],
        cxc: [],
        gastos: [],
        pagos: [],
        observacion: '',
        totales: {
          dias: {
            total: 0,
            seleccionados: 0,
            pendiente: 0,
          },
          cxc: {
            total: 0,
            seleccionados: 0,
            pendiente: 0,
          },
          gastos: 0,
          pagos: 0,
        },
      }
      datos.value = { ...datos.value, ...dato.item }
      datos.value.empleado = dato.item.empleado_json
      locatario.value = dato.item.puesto_json
      console.log(dato)
      if (dato.tipo == 1) {
        //solo modificar
        soloVer.value = false
      } else {
        //solo ver
        soloVer.value = true
        datos.value.cxc = datos.value.cxc.filter(item => item.checked == true)
      }

      totalizar()
    }

    return {
      FuncionesGenerales,
      getFechas,
      filtros,
      datos,
      validarFiltros,
      getPuesto,
      buscarDatos,
      soloVer,
      locatario,
      icons: {
        mdiCached,
        mdiDelete,
        mdiPlus,
        mdiCheckboxMarkedOutline,
        mdiCheckboxIntermediate,
        mdiCheckboxMarked,
        mdiCheckboxBlankOutline,
        mdiCart,
        mdiCashSync,
        mdiCashRefund,
        mdiCashRegister,
      },
      Reiniciar,
      agregarGasto,
      gasto,
      elimnarGasto,
      monedas,
      MonedaPais,
      GetPagos,
      totalizar,
      selectCXC,
      confirmarGuardado,
      guardarFormularioRef,
      getGuardar,
      guardando,
      VerCuenta,
    }
  },
}
</script>
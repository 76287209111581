var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Confirmacion")]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"text-center"},[_vm._v("Totales")]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v(" Dias ")]),_c('td',{staticClass:"text-right"},[_vm._v(" $"+_vm._s(_vm.totales.dias.seleccionados.toFixed(2))+" ")])]),_c('tr',[_c('td',[_vm._v(" CXC ")]),_c('td',{staticClass:"text-right"},[_vm._v(" $"+_vm._s(_vm.totales.cxc.seleccionados.toFixed(2))+" ")])]),_c('tr',[_c('td',[_vm._v(" Gastos ")]),_c('td',{staticClass:"text-right"},[_vm._v(" $"+_vm._s(_vm.totales.gastos.toFixed(2))+" ")])]),_c('tr',[_c('td',[_vm._v(" Pagos ")]),_c('td',{staticClass:"text-right"},[_vm._v(" $"+_vm._s(_vm.totales.pagos.toFixed(2))+" ")])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}})]),_c('tr',[_c('td',[_c('h4',[_c('span',{staticClass:"primary--text"},[_vm._v("Total pago")])])]),_c('td',{staticClass:"text-right"},[_c('h4',[_c('span',{staticClass:"primary--text"},[_vm._v(" $"+_vm._s((_vm.totales.cxc.seleccionados + _vm.totales.gastos + _vm.totales.pagos).toFixed(2))+" ")])])])]),_c('tr',[_c('td',[_c('span',{class:(_vm.totales.dias.seleccionados -
                                (_vm.totales.cxc.seleccionados
                                    + _vm.totales.gastos + _vm.totales.pagos)) > 0 ? 'error--text' : ''},[_vm._v("Resta")])]),_c('td',{staticClass:"text-right"},[_c('span',{class:(_vm.totales.dias.seleccionados -
                                (_vm.totales.cxc.seleccionados
                                    + _vm.totales.gastos + _vm.totales.pagos)) > 0 ? 'error--text' : ''},[_vm._v(" $"+_vm._s((_vm.totales.dias.seleccionados - (_vm.totales.cxc.seleccionados + _vm.totales.gastos + _vm.totales.pagos)).toFixed(2)))])])])])]},proxy:true}])})],1),_c('v-card-text',[_c('div',{staticClass:"text-center"},[_vm._v("Estado")]),_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var active = ref.active;
                                    var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'primary' : '',"dark":"","height":"100"},on:{"click":toggle}},[_c('v-scroll-y-transition',[_c('div',{staticClass:" white--text   flex-grow-1 text-center"},[_vm._v(" Presupuesto ")])])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var active = ref.active;
                                    var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'primary' : '',"dark":"","height":"100"},on:{"click":toggle}},[_c('v-scroll-y-transition',[_c('div',{staticClass:"white--text  flex-grow-1 text-center"},[_vm._v(" Finalizado ")])])],1)]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.getGuardar()}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>

    <div>
        <v-row>
            <v-col>
                <formulario  @getGuardar='getGuardar' ref="formularioRef"></formulario>
            </v-col>

        </v-row>

        <v-row>
            <v-col>
                <listar ref="listarRef" @GetItem="getSelectLista" class="mt-2"></listar>
            </v-col>
        </v-row>

    </div>

</template>

<script>
import Formulario from './componentes/Formulario.vue'
import Listar from './componentes/Listar.vue'
import { onBeforeMount, ref } from '@vue/composition-api';
export default {
    components: { Formulario, Listar },
    setup() {
        const formularioRef = ref(null)
        const listarRef = ref(null)

        const getSelectLista = (dato) =>{
            formularioRef.value.VerCuenta(dato) 
        }
        const getGuardar = (dato) =>{
            listarRef.value.cargarDatos()
        }

        return {
            formularioRef,
            getSelectLista,
            listarRef,
            getGuardar
        }
    },
}
</script>